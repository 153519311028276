/* eslint-disable no-throw-literal */
import * as Sentry from '@sentry/react';
import { splitFilename } from '@utils/splitFilename';
import axios from 'axios';
import { debounce, get, gt, gte, isEmpty, pickBy, set } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import TagManager from 'react-gtm-module';
import { useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { PillowForm } from '../../../components/PillowForm';
import { PanelLink } from '../../../components/shared/PanelList/styles';
import { config } from '../../../config';
import { componentTypes } from '../../../constants/componentTypes';
import { useAPI } from '../../../hooks/useAPI/useAPI';
import { useWorkflowPathname } from '../../../hooks/useWorkflowPathname/useWorkflowPathname';
import useLoanDocumentStore from '../../../store/loanDocumentsStore';
import baseModel from './Model';
import { UploadPanelLinkContainer } from './styles';

type LoanFilesPayload = {
  file: File;
  metadata: {
    autopayNumber: string;
    contentType: string;
    documentName: string;
  };
};

type LoanSignedUrlResponse = {
  url: string;
  uuid: string;
};

const Controller = () => {
  const methods = useForm();
  const api = useAPI();
  const navigate = useNavigate();
  const workflowName = useWorkflowPathname();

  const MAX_FILE_SIZE = 25e6; // 25MB

  const {
    selectedLoanDocument,
    addFile,
    removeFile,
    setSelectedLoanDocumentSubmitted,
    computed: { autopayNumber, uploadRouteAllowed },
  } = useLoanDocumentStore();

  const formValues = useWatch({
    control: methods.control,
  });

  const populatedFileInputs = useMemo(() => {
    return Object.values(formValues).filter(Boolean).length;
  }, [formValues]);

  const shouldShowUploadAnotherDocument = useMemo(() => {
    if (
      !selectedLoanDocument?.files ||
      gte(selectedLoanDocument?.files.length, 20) // limit files to 20
    ) {
      return false;
    }

    return populatedFileInputs === selectedLoanDocument?.files.length;
  }, [populatedFileInputs, selectedLoanDocument?.files]);

  // debounce is needed here because the validations are running multiple times
  // for the same file and we only want to report the first error (leading)
  const reportError = debounce(
    (file: File, message: string) => {
      Sentry.captureException(new Error(message), {
        extra: {
          Document: selectedLoanDocument?.label,
          FileSize: file.size,
          FileType: file.type || 'unknown',
          MaximumFileSize: MAX_FILE_SIZE,
        },
        tags: { 'document.type': selectedLoanDocument?.value },
      });
    },
    undefined, // wait time, leaving as default
    { leading: true },
  );

  const createLoanDocumentPayload = (
    formData: { [key: string]: { metadata: File } },
    autopayNumber: string,
  ) => {
    return Object.keys(formData).map((key, index) => {
      const file = get(formData, [key, 'metadata']);
      const contentType = get(formData, [key, 'metadata', 'type']);
      const { extension } = splitFilename(file.name);

      const documentName = [
        selectedLoanDocument?.folderName,
        `${autopayNumber}-`,
        selectedLoanDocument?.fileNamePrefix,
        index + 1,
        `.${extension}`,
      ]
        .filter(Boolean)
        .join('');

      return {
        file,
        metadata: {
          autopayNumber,
          contentType,
          documentName,
        },
      };
    });
  };

  const getSignedUrl = (
    payload: LoanFilesPayload,
  ): Promise<LoanSignedUrlResponse> =>
    api
      .post<never, LoanSignedUrlResponse>(
        `/documents/loan-signed-url`,
        payload.metadata,
        {
          timeout: config.fileUploadTimeout,
        },
      )
      .catch((error) => {
        throw error;
      });

  const uploadDocument =
    (payload: LoanFilesPayload) =>
    (response: LoanSignedUrlResponse): Promise<LoanFilesPayload> => {
      return axios
        .put(response.url, payload.file, {
          headers: {
            'Content-Type': payload.file.type,
          },
        })
        .then(() => payload)
        .catch((error) => {
          throw error;
        });
    };

  const backClicked = () => {
    navigate(`/${workflowName}/loan-documents/list`);
  };

  const onSubmit = useAsyncCallback(
    async (formData: { [key: string]: { metadata: File } }) => {
      const cleanFormData = pickBy(
        formData,
        (fieldValue) => !isEmpty(fieldValue),
      );

      if (!cleanFormData || !autopayNumber) {
        return;
      }

      const promises = createLoanDocumentPayload(
        cleanFormData,
        autopayNumber,
      ).map((payload: LoanFilesPayload) =>
        getSignedUrl(payload).then(uploadDocument(payload)),
      );

      try {
        await Promise.all(promises).then(() => {
          TagManager.dataLayer({
            dataLayer: {
              event: 'uploaded-loan-document',
              LoanDocumentType: selectedLoanDocument?.value,
              autopayNumber,
              Count: populatedFileInputs,
            },
          });

          setSelectedLoanDocumentSubmitted();
        });

        navigate(`/${workflowName}/loan-documents/submitted`);
      } catch (error) {
        const message: string = get(error, '0.message', '');

        if (message.includes('Network Error')) {
          throw [
            'A network error has occurred. Please try again with a stable internet connection.',
          ];
        }

        throw [
          'There was an unexpected error attempting to upload files. Please try again.',
        ];
      }
    },
  );

  const model = useMemo(() => {
    set(baseModel, 'headerBlock.title', selectedLoanDocument?.label);
    set(baseModel, 'headerBlock.paragraph', selectedLoanDocument?.paragraph);
    set(baseModel, 'template.header.onBack', backClicked);
    set(baseModel, 'form.globalErrors', onSubmit.error);
    set(baseModel, 'info', selectedLoanDocument?.info);
    set(baseModel, 'form.actions.secondary.handler', backClicked);
    set(baseModel, 'form.actions.primary.isLoading', onSubmit.loading);
    set(
      baseModel,
      'form.actions.primary.isDisabled',
      populatedFileInputs === 0,
    );
    set(
      baseModel,
      'form.actions.primary.onClick',
      methods.handleSubmit(onSubmit.execute),
    );

    const { files = [], label } = selectedLoanDocument || {};

    const formFields = files.map((file, index) => {
      const nonZeroIndex = index + 1;

      const labelWithCount = `${label} ${
        gte(nonZeroIndex, 2) ? nonZeroIndex : ''
      }`;

      const allowFileRemove = gt(files.length, 1);

      return {
        name: file,
        key: file,
        label: labelWithCount,
        removeable: allowFileRemove,
        component: componentTypes.UPLOAD_REMOVEABLE,
        removeClicked: () => {
          removeFile(file);
          methods.unregister(file);
        },
        validationRules: {
          required: false,
          validate: {
            format: (payload: { metadata: File }) => {
              // prevent validation on empty fields
              if (!payload) {
                return true;
              }

              if (!/jpeg|png|pdf/.test(payload.metadata.type)) {
                reportError(
                  payload.metadata,
                  'User selected a file format which is not currently supported',
                );

                return 'Uploaded file format is not supported.';
              }

              return true;
            },
            size: (payload: { metadata: File }) => {
              // prevent validation on empty fields
              if (!payload) {
                return true;
              }

              if (payload.metadata.size > MAX_FILE_SIZE) {
                reportError(
                  payload.metadata,
                  'User selected a file size which is greater than the maximum allowed',
                );

                return 'Uploaded file size exceeds 25MB.';
              }

              return true;
            },
          },
        },
      };
    });

    set(baseModel, 'form.fields', formFields);

    return baseModel;
  }, [
    methods,
    methods.handleSubmit,
    methods.unregister,
    selectedLoanDocument?.files,
    selectedLoanDocument?.label,
    selectedLoanDocument?.info,
    removeFile,
    onSubmit,
  ]);

  useEffect(() => {
    if (!uploadRouteAllowed) {
      navigate(`/${workflowName}/dashboard`);
    }
  }, []);

  return (
    <PillowForm methods={methods} presModel={model}>
      {shouldShowUploadAnotherDocument && (
        <UploadPanelLinkContainer>
          <PanelLink onClick={addFile}>+ Upload another document</PanelLink>
        </UploadPanelLinkContainer>
      )}
    </PillowForm>
  );
};

Controller.displayName = 'LoanDocumentsUpload.Controller';
export default Controller;
