import { splitFilename } from '@utils/splitFilename';

import { config } from '../../../config';
import useApiStore from '../../apiStore';
import { LoanDocumentType } from '../types/LoanDocumentType';
import { LoanSignedUrlResponseType } from '../types/LoanSignedUrlResponseType';
import useNewLoanDocumentStore from '../useNewLoanDocumentStore';
import { reportURLError } from './reportURLError';

export const getSignedUrl =
  (
    radixHttpRequest = useApiStore.getState().radixApi(),
    getFileName = useNewLoanDocumentStore.getState().getFileName,
  ) =>
  (
    document: LoanDocumentType,
    userId: string,
    count: number,
  ): Promise<LoanSignedUrlResponseType> => {
    const { extension } = splitFilename(document.file.name);

    const originalFileName = getFileName(document.file);

    return radixHttpRequest
      .post<never, LoanSignedUrlResponseType>(
        `/2.0/documents/loan-signed-url`,
        {
          contentType: document.file.type,
          count,
          documentType: document.type,
          extension,
          originalFileName,
          userId,
        },
        config.loanDocuments.signedUrlConfig,
      )
      .catch(reportURLError(document));
  };
